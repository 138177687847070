import module from 'module';
import _ from 'lodash';

const templateUrl = require('./pawn-loan-addition.template.html');
module.component('customerPawnLoanAddition', {
  templateUrl: templateUrl,
  controller: function ($route, $location, http, customerCache, confirmation, notification, pawnTagCache, newPawnService,
                        command, propertyConfigService) {
    let that = this;
    that.cfg = propertyConfigService;
    that.banks = [];

    // form parameters
    that.absoluteReleaseAmount = null;
    // denomination group
    that.denominationGroup = null;
    // new pawn owner
    that.newOwner = null;
    // loan addition command input object
    that.commandInput = {
      changeOwner: false,
      newOwnerId: null,
      productId: null,
      principalIncrease: null,
      totalAmount: null,
      denominationBundle: null
    };
    that.currentAppraisalAmount = null;

    const customerId = $route.current.params['customerId'];
    const pawnId = $route.current.params['pawnId'];

    that.$onInit = async () => {
      const [pawns, tags] = await Promise.all([customerCache.pawns(customerId).toPromise(), pawnTagCache.toPromise()]);
      that.pawn = _.find(pawns, (l) => l.id == pawnId);
      that.commandInput.productId = that.pawn.id;
      that.tags = tags;
    }

    /**
     * If release amount is positive denomination group should be OUTGOING.
     * If requested principal exceeds pawn valuation -> group should be set to null.
     */
    const updateDenominationGroup = (calculation) => {
      that.denominationGroup = null;
      if (calculation && !calculation.requestedPrincipalExceedsPawnValuation) {
        that.denominationGroup = that.commandInput.totalAmount > 0 ? 'OUTGOING' : 'INCOMING';
      }
    };

    that.setNewOwner = (customer) => {
      that.newOwner = customer;
      if (customer) that.commandInput.newOwnerId = customer.customerId;
    };

    that.removeNewOwner = () => {
      that.newOwner = null;
      that.commandInput.newOwnerId = null;
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/pawns/${pawnId}`);

    that.calculate = async () => {
      that.calculation = await http.post(`/products/pawns/${pawnId}/loan-addition/calculate`, {productId: pawnId, amount: that.amount}).toPromise();
      that.commandInput.principalIncrease = that.calculation.total;
      that.commandInput.totalAmount = that.calculation.effectiveReleaseAmount;
      that.absoluteReleaseAmount = Math.abs(that.calculation.effectiveReleaseAmount);

      that.supportedPawnTagIds = [that.calculation.tagId];
      updateDenominationGroup(that.calculation);
    };

    that.isLoanAdditionPossible = () => {
      return that.calculation
        && !that.calculation.requestedPrincipalExceedsPawnValuation
        && !that.calculation.requestedAmountDoesntCoverCharges;
    }

    that.loanAddition = async () => {
      const proceed = await confirmation(`Do you want to perform loan addition (${that.calculation.total} PHP)?`);
      if (!proceed) {
        return;
      }

      const response = await command.execute('LoanAdditionPawn', that.commandInput).toPromise();
      if (response.approvalRequired) {
        return;
      }

      customerCache.pawns(customerId).refetch();
      $location.path(`/customer/${customerId}/pawns/${response.id}`);
    };
  }
});
