import module from 'module';
import _ from 'lodash';

const templateUrl = require('./pawn-change-owner.template.html');
module.component('customerPawnChangeOwner', {
  templateUrl: templateUrl,
  controller: function ($route, $location, http, dict, customerCache, productDefinitionService, confirmation, notification,
                        newPawnService, popup, pawnTagCache, command, propertyConfigService) {
    let that = this;
    that.cfg = propertyConfigService;
    that.banks = [];
    that.interbranch = false;

    // form parameters
    that.remainingAmount = null;
    // new pawn owner
    that.newOwner = null;
    // renewal command input object
    that.commandInput = {
      productId: null,
      amount: null,
      denominationBundle: null,
      newOwnerId: null
    };

    let customerId = $route.current.params['customerId'];
    let pawnId = $route.current.params['pawnId'];

    that.setNewOwner = (customer) => {
      that.newOwner = customer;
      if (customer) that.commandInput.newOwnerId = customer.customerId;
    };

    that.removeNewOwner = () => {
      that.newOwner = null;
      that.commandInput.newOwnerId = 0;
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/pawns/${pawnId}`);

    that.$onInit = async () => {
      await that.calculate();
    };

    that.calculate = async () => {
      try {
        [that.pawns, that.tags] = await Promise.all([customerCache.pawns(customerId).toPromise(), pawnTagCache.toPromise()]);
        that.pawn = _.find(that.pawns, (l) => l.id == pawnId);
        that.commandInput.productId = that.pawn.id;

        that.calculation = await http.post(`/products/pawns/${pawnId}/renewal/${that.pawn.typeId}/calculate`).toPromise();
        that.commandInput.amount = that.calculation.total;
        that.supportedPawnTagIds = [that.calculation.tagId];
      } catch (error) {
        popup({
          header: 'Error',
          text: error.errorMessage
        });
      }
    };

    that.changeOwner = async () => {
      const proceed = await confirmation(`Do you want to change the owner of the pawn and renew it by cash (${that.calculation.total} PHP)?`);
      if (!proceed) {
        return;
      }

      const response = await command.execute('ChangeOwnerPawn', that.commandInput).toPromise();
      if (response.approvalRequired) {
        return;
      }

      const newOwnerId = that.newOwner.customerId;
      customerCache.pawns(customerId).refetch();
      // After success redirect user to CIF of new pawn owner
      customerCache.pawns(newOwnerId).refetch();
      $location.path(`/customer/${newOwnerId}/pawns/${response.id}`);
    };
  }
});
