import module from 'module';
import _ from 'lodash';

const templateUrl = require('./pawn-redemption.template.html');
module.component('customerPawnRedemption', {
  templateUrl: templateUrl,
  controller: function ($route, $location, http, dict, customerCache, productDefinitionService, confirmation,
                        notification, pawnItemCache, command, propertyConfigService) {
    let that = this;
    that.cfg = propertyConfigService;
    that.banks = [];

    // form parameters
    that.remainingAmount = null;
    // redemption command input object
    that.commandInput = {
      productId: null,
      amount: null,
      denominationBundle: null
    };

    let customerId = $route.current.params['customerId'];
    let pawnId = $route.current.params['pawnId'];

    customerCache.pawns(customerId).toObservable().subscribe(pawns => {
      that.pawn = _.find(pawns, (l) => l.id == pawnId);
      that.commandInput.productId = that.pawn.id;
      that.commandInput.amount = that.pawn.outstandingBalance;
    });

    that.redirectBack = () => $location.path(`/customer/${customerId}/pawns/${pawnId}`);

    that.redeem = async () => {
      const proceed = await confirmation(`Do you want to redeem the pawn by cash (${that.pawn.outstandingBalance} PHP)?`);
      if (!proceed) {
        return;
      }

      const response = await command.execute('RedeemPawn', that.commandInput).toPromise();
      if (response.approvalRequired) {
        return;
      }

      customerCache.pawns(customerId).refetch();
      pawnItemCache.evict();
      that.redirectBack();
    };
  }
});