import module from 'module';
import _ from 'lodash';
import moment from 'moment';
import {isReleasedOrClosed} from 'components/customer/pawns/customer-pawns/customer-pawns.component';

const templateUrl = require('./pawn-update.template.html');
module.component('customerPawnsUpdate', {
  templateUrl: templateUrl,
  controller: function ($route, $location, http, customerCache, pawnProductsCache, confirmation, notification,
                        branchService, authentication, pawnTagCache, newPawnService, command, popup, systemPropertyCache) {

    const customerId = $route.current.params['customerId'];
    const pawnId = $route.current.params['pawnId'];

    const that = this;
    that.pawn = null;
    that.commandInput = null;
    that.isOperational = true;
    that.checkAppraisalAmountRequired = false;
    that.statuses = ['ACTIVE', 'MATURE', 'PAST_DUE_PERFORMING', 'PAST_DUE_NON_PERFORMING'];

    that.$onInit = async () => {
      const [branches,
        tags,
        pawns,
        pawnTypes,
        systemProperties
      ] = await Promise.all([
        branchService.toPromise(),
        pawnTagCache.toPromise(),
        customerCache.pawns(customerId).toPromise(),
        pawnProductsCache.toPromise(),
        systemPropertyCache.toPromise()
      ]);
      that.branch = branches.find(br => br.id === authentication.context.branchId);
      that.pawn = pawns.find((pawn) => Number(pawn.id) === Number(pawnId))
      that.pawnProduct = pawnTypes.find((type) => type.id === that.pawn.typeId);
      that.supportedPawnTagIds = _.intersection(that.pawnProduct.supportedPawnTagIds, that.branch.supportedPawnTagIds);
      that.roundDownToNearestHundreds = _.find(systemProperties, {code: 'PRINCIPAL_AMOUNT_ROUND_DOWN_TO_NEAREST_HUNDREDS'}).value === 'TRUE';

      // If pawn status is not included in [that.statuses] -> disable status update
      if (!that.statuses.includes(that.pawn.status)) {
        that.isOperational = false;
      }

      that.suggestedAppraisalAmount = await that.calculateSuggestedAppraisalAmount();
      that.pawn.tag = tags.find((tag) => tag.id === that.pawn.tagId).name;

      // Prepare command input object
      that.commandInput = {
        productId: that.pawn.id,
        status: that.pawn.status,
        tagId: that.pawn.tagId,
        penaltyAmount: that.pawn.penaltyAmount,
        pastDueInterestAmount: that.pawn.pastDueInterestAmount,
        lastTransaction: moment(that.pawn.lastTransaction, 'YYYY-MM-DD').toDate(),
        lastCustomerTransaction: moment(that.pawn.lastCustomerTransaction, 'YYYY-MM-DD').toDate()
      };
    };

    that.calculateSuggestedAppraisalAmount = async () => {
      const request = newPawnService.createPawnRequest(
        that.pawnProduct,
        {
          ...that.pawn,
          amount: that.pawn.principalAmount
        },
        that.pawn.items
      );

      const calculatedPawn = await newPawnService.calculatePawnPromise(request, that.pawnProduct);
      return calculatedPawn.appraisalAmount;
    };

    that.resetCalculate = () => {
      that.checkAppraisalAmountRequired = that.commandInput.appraisalAmount != null;
    }

    that.checkAppraisalAmount = async () => {
      if (that.roundDownToNearestHundreds) {
        that.commandInput.appraisalAmount = newPawnService.roundDownToNearestHundreds(that.commandInput.appraisalAmount);
      }
      that.checkAppraisalAmountRequired = false;
    }

    that.update = async () => {
      const proceed = await confirmation(`Do you want to update pawn ${that.pawn.productNumber}?`);
      if (!proceed) {
        return;
      }

      // Copy command input object and transform dates to strings
      const commandInput = angular.copy(that.commandInput);
      if (commandInput.lastTransaction) commandInput.lastTransaction = moment(commandInput.lastTransaction).format('YYYY-MM-DD');
      if (commandInput.lastCustomerTransaction) commandInput.lastCustomerTransaction = moment(commandInput.lastCustomerTransaction).format('YYYY-MM-DD');
      
      commandInput.tagId = that.pawn.tagId;
      const updateCommand = isReleasedOrClosed(commandInput) ? 'UpdateReleasedPawn' : 'UpdateUnreleasedPawn';

      const response = await command.execute(updateCommand, commandInput).toPromise();
      if (response.approvalRequired) {
        return;
      }

      popup({
        header: "Pawn Update Successful",
        text: "Pawn update successful. Please print the updated pawn ticket."
      });

      customerCache.pawns(customerId).refetch();
      that.redirectBack();
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/pawns/${pawnId}`);
  }
});
