import module from 'module';

import templateUrl from './risk-assessment.template.html';
import './risk-assessment.style.less';

class RiskAssessmentView {
  constructor(http, confirmation, $location, dict, command, riskAssessmentEntryCache) {
    this.http = http;
    this.confirmation = confirmation;
    this.$location = $location;
    this.dict = dict;
    this.command = command;
    this.riskAssessmentEntryCache = riskAssessmentEntryCache;
  }

  async $onInit() {
    const entries = await this.riskAssessmentEntryCache.toPromise();
    this.sortedEntries = entries.sort((a, b) => a.orderNo - b.orderNo); // order by order_no based on risk level (low -> normal -> high)

    this.dict.onLoadingComplete(() => {
      this.riskDictionaryEntries = this.dict['CUSTOMER_RISK_LEVEL'];
    });
  }

  onEnabledChange(index) {
    let entry = this.sortedEntries[index];
    let minPoints = 0;
    let maxPoints = 0;

    if (entry.enabled) {
      maxPoints = 1;
    }

    entry.minPoints = minPoints;
    entry.maxPoints = maxPoints;
  }

  isMinPointsGtPreviousMaxPoints(index) {
    const current = this.sortedEntries[index];

    if (!current.enabled) {
      return true;
    }

    if (index === 0) {
      return current.minPoints === 0;
    }

    // Find the nearest previous enabled risk assessment.
    let nearestEnabledPreviousEntry = null;
    for (let i = index; i > 0 && nearestEnabledPreviousEntry === null; i--) {
      let entry = this.sortedEntries[i - 1];
      if (entry.enabled) {
        nearestEnabledPreviousEntry = entry;
      }
    }
    return nearestEnabledPreviousEntry === null ? true : current.minPoints > nearestEnabledPreviousEntry.maxPoints;
  }

  isMaxPointsGteMinPoints(index) {
    const current = this.sortedEntries[index]
    
    if (!current.enabled) {
      return true;
    }

    return current.maxPoints >= current.minPoints;
  }

  areAllEntriesValid() {
    if (!this.sortedEntries) {
      return false;
    }

    for (let i = this.sortedEntries.length - 1; i > 0; i--) {
      if (!this.isMinPointsGtPreviousMaxPoints(i)) {
        return false;
      }

      if (!this.isMaxPointsGteMinPoints(i)) {
        return false;
      }
    }

    return true;
  }

  async save() {
    const confirmation = await this.confirmation('Do you want to update it?');
    if (!confirmation) {
      return;
    }

    await this.command.execute('UpdateRiskAssessments', {entries: this.sortedEntries}).toPromise();
    this.riskAssessmentEntryCache.refetch();
    this.goBack();
  }

  goBack() {
    this.$location.path('/admin/compliance')
  }
}

module.component('riskAssessmentView', {
  templateUrl,
  controller: RiskAssessmentView
});
