import module from 'module';
import {BigNumber} from 'bignumber.js';
import _ from 'lodash';
import {MODE} from '../stock-items-inventory-view.component';

import './stock-items-send-summary.style.less';
import templateUrl from './stock-items-send-summary.template.html';

module.component('stockItemsSendSummary', {
  templateUrl: templateUrl,
  bindings: {
    mode: '=',
    items: '<'
  },
  controller: function ($scope, $routeParams, $location, $filter, $route, command, http,
                        authentication, notification, confirmation, branchService, reportService,
                        popup, systemPropertyCache) {

    const that = this;
    that.MODE = MODE;
    that.branches = [];

    branchService.toObservable().first().subscribe(branches => {
      that.branches = branches;
    });

    systemPropertyCache.toObservable().first().subscribe(properties => {
      that.headOfficeId = _.find(properties, {code: 'HEAD_OFFICE_BRANCH_ID'}).value
    });

    that.sumTotalPrice = (items) => {
      return items.map(entry => new BigNumber(entry.sellingPrice))
             .reduce((a, b) => a.plus(b), new BigNumber(0));
    };

    that.validSellingPrice = () => {
      return that.items.every(item => item.sellingPrice !== null);
    }

    that.isSendForDisplay = () => {
      return that.mode.name === that.MODE.SEND_FOR_DISPLAY.name
    };

    that.cancel = () => {
      that.mode = null;
    };

    that.send = async () => {
      const confirm = await confirmation(that.mode.generatePrompt(that.items));
      if (!confirm) {
        return;
      }

      const response = await command.execute(
        that.mode.command,
        that.mode.generateInput(that.items, that.branch ? that.branch.id : null, that.headOfficeId)
      ).toPromise();

      if (response.approvalRequired) {
        return;
      }

      const executionErrors = response.output.executionErrors;
      if (executionErrors && !_.isEmpty(executionErrors)) {
        const header = '<p><span class="red"><strong>WARNING!</strong> Items listed below resulted in errors and will not be send:</span></p>';
        const body = [];
        _.forOwn(executionErrors, (errValue, errMessage) => {
          body.push(`<strong>${errMessage}</strong>: ${errValue}<br>`);
        });
        const errorDetails = [header, ...body].join('');
        await popup({
          header: `${that.mode.name} result`,
          text: errorDetails,
          renderHtml: true
        });
      }
      $route.reload();
    };

    that.downloadXls = () => reportService.downloadXls({
      reportCode: 'StockInventoryItemsReport',
      params: {
        ids: that.items.map(item => item.id) // hidden parameter
      }
    });

    that.print = () => window.print();
  }
});
