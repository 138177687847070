import {Observable} from 'rxjs/Observable'
import 'rxjs/add/observable/fromPromise';
import BigNumber from 'bignumber.js';
import _ from 'lodash';
import moment from 'moment';

import module from 'module';
import {defaultHeaderLabels, flattenSubaccounts, mergedLedger} from '../common/gl.utils';

const templateUrl = require('./gl-transaction-create.template.html');


const CONTINGENT_ACCOUNT_NAME = 'CONTINGENT';


module.component('glTransactionCreate', {
  templateUrl,
  bindings: {'backdated': '<'},
  controller: function ($scope, $routeParams, $location, $filter, glLedgerService, branchService,
                        breadcrumbs, glAccountService, glTransactionService,
                        confirmation, notification, authentication, systemPropertyCache) {
    const that = this;
    that.ledgerId = parseInt($routeParams.accountId);
    that.minBackdatedPostingDate = null;
    that.maxBackdatedPostingDate = null;
    that.header = [];

    that.model = {
      operations: [
          {/*-*/}
      ],
      transactionType: 'NORMAL',
      remarks: '',
    };

    that.allAccounts = [];

    that.applyAccountsFilter = () => {
      that.accountOptions = that.allAccounts.filter(account => {
        if(that.model.transactionType === CONTINGENT_ACCOUNT_NAME) {
          return account.accountGroup === CONTINGENT_ACCOUNT_NAME;
        }

        return account.accountGroup !== CONTINGENT_ACCOUNT_NAME;
      });
    };

    that.resetForm = () => {
      that.model = Object.assign(that.model, {
        operations: [{
        }],
        remarks: '',
      });

      $scope.createTransactionForm.$setPristine();
      that.applyAccountsFilter();
    };

    that.addOperation = () => {
      that.model.operations.push({});
    };

    that.removeOperation = idx => {
      that.model.operations = _.reject(that.model.operations, (value, index) => idx === index);
    };

    // Copy newest remark value to all operations with empty remark fields
    that.copyRemarks = o => {
      if (o && o.remarks) {
        let defaultRemark = o.remarks;
        that.model.operations.forEach(op => {
            op.remarks = op.remarks || defaultRemark;
          });
      }
    };

    const accountsPromise = glAccountService.fetchAccounts(that.ledgerId, {leafOnly: true})
      .toPromise();

    Observable.fromPromise(accountsPromise)
      .subscribe(accounts => {
        that.allAccounts = flattenSubaccounts(accounts);
        that.applyAccountsFilter();
      });

    const dataUpdates = mergedLedger({
      glLedgerService,
      branchService,
      accountId: that.ledgerId
    })
      .subscribe(ledger => {
        _.set(breadcrumbs, 'options', {
            'gl-transaction-list-label': ledger.template.name,
            'gl-transaction-create-label': 'Add Manual',
        });

        that.header = defaultHeaderLabels(ledger);
      });

    const bigSumBy = (collection, itemProperty) =>
      collection.reduce((sum, item) => sum.add(item[itemProperty] || 0), new BigNumber(0));

    that.calculateDebitSum = () => {
      return bigSumBy(that.model.operations, 'debitAmount');
    };

    that.calculateCreditSum = () => {
      return bigSumBy(that.model.operations, 'creditAmount');
    };

    that.isFormValid = () => {
      return $scope.createTransactionForm.$valid &&
        that.isTransactionBalanced() &&
        that.hasOperations() &&
        !that.hasEmptyAmountsInOperation();
    };

    that.isTransactionBalanced =  () => {
      return that.model.transactionType === CONTINGENT_ACCOUNT_NAME || that.calculateCreditSum().equals(that.calculateDebitSum());
    };

    that.hasOperations = () => {
      return !_.isEmpty(that.model.operations);
    };


    that.hasEmptyAmountsInOperation = () => {
      return that.model.operations
        .filter(operation => operation.debitAmount === 0 && operation.creditAmount === 0)
        .length > 0;
    };

    that.resetDebitAmount = operation => {
      if(operation.creditAmount > 0) {
        operation.debitAmount = 0;
      }
    };

    that.resetCreditAmount = operation => {
      if(operation.debitAmount > 0) {
        operation.creditAmount = 0;
      }
    };

    that.cancelChanges = () => {
      const parentPath = _.nth(breadcrumbs.get(), -2).path;
      confirmation('Do you want to cancel? Canceling will discard all changes.', () => $location.path(parentPath));
    };

    that.parseFile = () => {
      if (that.file && that.file.length && that.file[0].id) {
        glTransactionService.batchCreateTransaction(that.file[0].id, that.ledgerId)
          .success((response) => {
            response.output.forEach(res => {
              res.transactionUnits.forEach(r => {
                let obj = {
                  account: _.find(that.accountOptions, {id: r.ledgerAccountId}),
                  backdatedPostingDate: new Date(res.backdatedPostingDate),
                  remarks: r.remarks
                }

                if (r.entryType === 'DEBIT') {
                  obj.debitAmount = r.amount;
                  obj.creditAmount = 0;
                } else if (r.entryType === 'CREDIT') {
                  obj.creditAmount = r.amount;
                  obj.debitAmount = 0;
                }

                that.model.operations.push(obj);
              });
            });

            notification.show('Success', 'Batch transactions loaded successfully.');
          })
          .error(() => {
            notification.show('Error', 'Failed to load batch transactions.');
          });
      }
    }

    that.submit = function () {
      if(!that.isFormValid()) {
        return;
      }

      confirmation('Do you want to create the transaction?', () => {
        // Group by backdated posting date for backdated transactions, otherwise put everything into key 0.
        const map = that.model.operations.reduce((a, b) => {
          const key = b.backdatedPostingDate || 0;
          if (!a[key]) {
            a[key] = [];
          }
          a[key].push(b);
          return a;
        }, {});

        const requestData = Object.entries(map).map(([key, value]) => {
          return {
            ledgerId: that.ledgerId,
            backdated: that.backdated,
            backdatedPostingDate: key !== 0 ? $filter('nxDate')(key) : null,
            contingent: that.model.transactionType === CONTINGENT_ACCOUNT_NAME,
            transactionType: 'MANUAL',
            transactionUnits: value.map(operation => {
              return {
                ledgerAccountId: operation.account.id,
                entryType: operation.creditAmount ? 'CREDIT' : 'DEBIT',
                amount: operation.creditAmount || operation.debitAmount,
                remarks: operation.remarks
              }
            })
          }
        });

        glTransactionService.createTransaction(requestData)
          .success(() => {
            notification.show('Success', 'Transaction created successfully');
            that.resetForm();
            $location.path(`/general-ledger/transactions/${that.ledgerId}`);
          });
      })
    };


    $scope.$watch('$ctrl.transactionType', () => {
      that.applyAccountsFilter();
    });

    const setBackdatingDateRange = async () => {
      if(that.backdated) {
        const branches = await branchService.toPromise();
        const properties = await systemPropertyCache.toPromise();
        const branch = branches.find(b => Number(b.id) === Number(authentication.context.branchId));
        const daysCount = properties.find(p => p.code === 'LEDGER_MAX_BACKDATING_DAYS');
        if (branch && daysCount && parseInt(daysCount.value) > 0) {
          const postingDate = moment(branch.postingDate);
          that.maxBackdatedPostingDate = postingDate.subtract(1, 'day').format('YYYY-MM-DD');
          that.minBackdatedPostingDate = postingDate.subtract(daysCount.value, 'day').format('YYYY-MM-DD');
        }
      }
    };

    that.$onInit = () => {
      setBackdatingDateRange();
    };

    that.$onDestroy = () => {
      dataUpdates.unsubscribe();
    };
  }
});
